<template>
  <div class="contenedor">
    <v-row class="mx-auto">
      <v-col cols="12" sm="12" md="8">
        <v-alert
        v-model="alert"
        border="left"
        close-text="Close Alert"
        color="deep-purple accent-4"
        dark
        dismissible
      >
        {{message}}
      </v-alert>
        <v-card class="elevation-12">
          <v-toolbar class="py-6" color="gray accent-4" extended dark flat>
            <v-toolbar-title></v-toolbar-title>
            <div class="flex-grow-1"></div>
            <div align="center">
              <img src="img/logo_foresta_blanco.png" width="150" alt />
            </div>
            <div class="flex-grow-1"></div>
          </v-toolbar>

          <v-card-text>
            <v-form>
              <v-text-field label="Email" name="login" prepend-icon="mdi-account" type="text" v-model="email"></v-text-field>

              <v-text-field
                id="password"
                label="Contraseña"
                name="password"
                v-model="password"
                prepend-icon="mdi-lock"
                type="password"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn color="lime accent-4" @click="signInUser()">Ingresar</v-btn>
          </v-card-actions>
          <v-card-actions align="center" justify="center">
            <div class="flex-grow-1"></div>
            <h4 @click="recuperarPass()">Recuperar Contraseña</h4>
            <div class="flex-grow-1"></div>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>


<script>

export default {
  data: () => ({
    email:"",
    password:"",
    message: "",
    alert :false,
  }),
  beforeEnter(){
    if(this.$store.state.user.isAdmin){
      //this.$router.replace({name: 'Admin Contenido'});
    } else {
      console.log("user not found");
    }
  },
  computed: {
    user(){
      return this.$store.getters.getUser;
    },
    nextRoute () {
      return this.$route.query.redirect || '/admin/portadas'
    }
  },
  watch: {
    claims () {
      if(this.user){
        this.$router.replace(this.nextRoute)
      }
    }
  },
  methods: {
    
    signInUser() {
      var self = this;
      firebase.auth().signInWithEmailAndPassword(this.email, this.password)
        .then(user=>{
          console.log(user);
          self.$router.replace(this.nextRoute);
        })
        .catch(function(error) {
          // Handle Errors here.
          var errorCode = error.code;
          console.log(error);
          switch(error.code){
            case "auth/invalid-email":  
              self.message = "Correo electrónico no valido";
              break;
            case "auth/wrong-password":
              self.message = "La contraseña es incorrecta o el usuario no ha creado una";
              break;
            case "auth/user-not-found":
              self.message = "El usuario no existe o la contraseña es incorrecta";
              break;
            case "auth/user-disabled":
              self.message = "Esta cuenta ha sido deshabilitada, revisar con el adminstrador";
              break;
            default:
              self.message = error.message;
              break;

          }
          
          self.alert = true;
          // ...
        });
        
    },
    recuperarPass(){
      var self = this;
      firebase.auth().sendPasswordResetEmail(this.email).then(()=>{
        self.message = "Email enviado";
        self.alert = true;
      }).catch(function(error) {
        if(error.code ==  "auth/invalid-email"){
          self.message = "Correo electrónico no valido";
        } else {
          self.message = error.message;
        }
        
        self.alert = true;
      });
      /*
      var auth = firebase.auth();
      auth.sendPasswordResetEmail(this.email).then(function() {
        self.message = "Email enviado";
        self.alert = true;
      }).catch(function(error) {
        self.message = error;
        self.alert = true;
      });*/
    }
  }
};
</script>

<style lang="scss" scoped>
.contenedor {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  align-content: center;
  margin:0 auto;
  padding:20px;
  width:100vw;

  .row{
    min-width:800px;
    justify-content:center;
    align-items:center;
  }
}

</style>