<template>
  <div class="videos">
    <video id="doc-player" playsinline autoplay controls class="cld-video-player cld-fluid"></video>
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from '@/components/NavBar.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Login',
  components: {
    NavBar,
    Footer
  },
  data(){
    return{
      section:{},
      chapter:{video:""},
      vid:"",
    }
  },
  beforeMount(){
    
  },
  mounted(){
    console.log("video",this.chapter,this.$route.params)
    this.title = this.$route.params.id ? this.$route.params.id : "";
    if(this.$store.state.sections.length > 0){
      this.getContenido();
    } else {
      this.$store.dispatch("getSections")
      .then(()=>{
        this.getContenido();
      })
    }
  },
  computed: {
    titulo(){
      return this.$store.state.activeTitle;
    }
  },
  metaInfo(){
    return { 
     title: this.$route.params.seccion + " | " + this.$route.params.id,
    }
  },
  methods:{
    login(){
      //TODO log user
    },
    getContenido(){
      //Check if active section and active title are set
      if(!this.$store.activeChapter){
        this.$store.dispatch("setChapterBySlug",this.$route.params.id)
        .then(()=>{
          this.chapter = this.$store.state.activeChapter;
          console.log(this.chapter);
          this.playVideo(this.chapter.video);
        })
      }
      else{
        this.section = this.$store.state.activeSection;
        this.chapter = this.$store.state.activeChapter;
        this.playVideo(this.chapter.video);
      }
      //Get video from cloudinary
    },
    
    playVideo(vid){
      //console.log("play video",this.chapter.video.url);
      var vidplayer = cloudinary.videoPlayer('doc-player', { cloud_name: 'demo' });
      vidplayer.source(this.chapter.video.secure_url)
      //const video = document.querySelector('video');
      //video.src = this.chapter.video.url;
      gtag('event', 'video', {'seccion': this.$route.params.seccion, "id":this.$route.params.id, });
      
      vidplayer.addEventListener('canplay',(event) => {
        //console.log('Video can start, but not sure it will play through.');
        video.play();
      })
     
      
      vidplayer.addEventListener('ended', (event) => {
        console.log(this.$store.state.activeSection);
        if(this.$store.state.activeSection){
          this.$router.go(-1)
        }
          
      });
    }
  }
}
</script>


<style lang="scss">
.video{
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

video{
  width: 100%;
}

</style>