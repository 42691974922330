<template>
  <v-app>
    <v-main class="pa-1">
      <AdminNavBar />
      <div class="container">
        <h1>Portadas sección contenido</h1>
        <v-data-table
          :headers="headers"
          :items="contenido"
          sort-by="name"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-dialog v-model="dialog" max-width="500px">
                <v-card py-5>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model="editedItem.name"
                            label="Sección"
                          ></v-text-field>
                          <v-col>
                          <v-switch
                            v-model="editedItem.active"
                            inset
                            label="Activa"
                          ></v-switch>
                        </v-col>
                        </v-col>
                        <v-col v-if="editedItem.img">
                           <img :src="editedItem.img" alt="" srcset="" />
                           <v-btn color="red" dark @click="editedItem.img=null">
                             <v-icon>mdi-delete</v-icon> Eliminar
                           </v-btn>
                        </v-col>
                        <v-col v-else>
                          <v-file-input
                            chips
                            filled
                            show-size
                            accept="image/png, image/jpeg"
                            prepend-icon="mdi-camera"
                            label="Subir portada"
                            v-model="editedItem.imgtoUpload"
                          ></v-file-input>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      Cancelar
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="saveFile">
                      Guardar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5"
                    >¿Estás seguro que deseas eliminar?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete"
                      >Cancelar</v-btn
                    >
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.img="{ item }">
            <img :src="item.img" alt="" srcset="" />
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-switch
              v-model="item.active"
              inset
              @change="updateStatus(item)"
            ></v-switch>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary" @click="initialize">
              Reset
            </v-btn>
          </template>
        </v-data-table>
      </div>
    </v-main>
  </v-app>
</template>
<script>
// @ is an alias to /src
import AdminNavBar from "@/components/AdminNavBar.vue";

export default {
  name: "Login",
  components: {
    AdminNavBar,
  },
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      uploading: false,
      headers: [
        {
          text: "Contenido",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Portada", value: "img" },
        { text: "Acciones", value: "actions", sortable: false },
      ],
      editedIndex: -1,
      editedItem: { name: "", img: "", active:true },
      defaultItem: { name: "", img: "", active:true },
    };
  },
  beforeMount(){
    this.$store.dispatch("getSections");
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nueva Portada" : "Editar Portada";
    },
    contenido(){
      return this.$store.getters.getSections;
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  methods: {
    editItem(item) {
      this.editedIndex = this.contenido.indexOf(item);
      this.editedItem = Object.assign({}, item);
      console.log(this.editedItem,this.editedIndex);
      this.dialog = true;
    },
    desactivar(item){
      this.editedIndex = this.contenido.indexOf(item);
      
    },
    updateStatus(item){
      this.editedIndex = this.contenido.indexOf(item);
      //console.log(this.editedIndex);
      this.editedItem = Object.assign({}, item);
      this.updateCover();
    },
    deleteItem(item) {
      this.editedIndex = this.contenido.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.contenido.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.contenido[this.editedIndex], this.editedItem);
      } else {
        this.contenido.push(this.editedItem);
      }
      this.close();
    },

    saveFile() {
      this.uploading = true;
      var self = this;
      if(this.editedItem && this.editedItem.imgtoUpload){
        var storageRef = firebase.storage().ref();
        var uploadTask2 = storageRef
          .child("portadas/" + this.editedItem.imgtoUpload.name)
          .put(this.editedItem.imgtoUpload);
        uploadTask2.on("state_changed",function(snapshot) {
            var progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload is " + progress + "% done");
            //on done add url to form
          },
          (error) => {
            // Handle unsuccessful uploads
            console.error(error);
          },
          () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            uploadTask2.snapshot.ref.getDownloadURL().then((downloadURL) => {
              console.log("File available at", downloadURL);
              //this.producto.imagenes.push({ name: file.file.name, url: downloadURL });
              self.updateCover(downloadURL);
              //console.log(downloadURL);
            });
          }
        );
      } else {
        this.editedItem.img = "https://via.placeholder.com/150";
        this.updateCover();
      }
    },

    updateCover(downloadURL){
      //console.log(this.editedItem)
      var self = this;
      this.editedItem.img = this.editedItem.img ? this.editedItem.img : "https://via.placeholder.com/150";
      console.log(this.editedItem);
      const data = {
        name:self.editedItem.name,
        img: downloadURL ? downloadURL : self.editedItem.img,
        tipo: self.editedItem.tipo,
        id: self.editedItem.id,
        active: self.editedItem.active
      }
      
      var db = firebase.firestore();
      db.collection("portadas").doc(self.editedItem.id).set(data)
      self.contenido[self.editedIndex].img = downloadURL ? downloadURL : self.editedItem.img;
      self.uploading = false;
      self.dialog = false;
    }
  },
};
</script>

<style lang="scss">
.v-main__wrap {
  display: flex;
}
</style>
