<template>
  <div class="contenido">
    <NavBar />
    <div class="logo">
      <img src="/img/logo.png" alt="">
    </div>
    <div>
      <div class="title">Contenido</div>
      <div class="sections" v-if="sections">
        <div class="section" v-for="s in sections" :key="s._id" @click="openSection(s)">
          <img :src="urlFor(s)" alt="">
          <p>{{s.categoria}}</p>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from '@/components/NavBar.vue'
import Footer from '@/components/Footer.vue'
import imageUrlBuilder from '@sanity/image-url'

const builder = imageUrlBuilder({
  projectId: 'j5fl1tzn',
  dataset: 'production',
})



export default {
  name: 'Login',
  components: {
    NavBar,
    Footer
  },
  data(){
    return {
      
    }
  },
  beforeMount(){
    this.$store.dispatch("getSections");
  },
  computed:{
    sections(){
      return this.$store.getters.getActiveSections;
    }
  },
  metaInfo(){
    return { 
     title: "Menu Contenido Impulso Digital",
    }
  },
  methods:{
    openSection(s){
      //TODO log user
      this.$store.dispatch("setSection",s);
      this.$router.push("seccion/"+s.slug.current);
      
    },
    urlFor(source,w=150) {
      //console.log(source.portada.asset._ref)
      if(source.portada == undefined) return "https://placehold.co/150";
      else{
        return builder.image(source.portada.asset._ref).width(w).url()
      }
      
    }
  }
}
</script>


<style lang="scss">

.contenido{
  width: 100%;
  flex:1;
  display: flex;
  flex-direction:column;
  justify-content:space-between;
  align-items:center;

  .title{
    font-size: 2rem;  
    font-weight:300;
  }
}

.logo{
  margin:20px auto;
   img{
    width:200px;
    @media screen and (min-width:760px) {
        width:320px
    }
   }
}

.sections{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.section{
  margin: 20px 10px;
  display: flex;
  flex-direction:column;


  img{
    width:150px;
    height:150px;
    background: rgb(69,130,186);
    background: -moz-linear-gradient(0deg, rgba(69,130,186,1) 0%, rgba(56,180,226,1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(69,130,186,1) 0%, rgba(56,180,226,1) 100%);
    background: linear-gradient(0deg, rgba(69,130,186,1) 0%, rgba(56,180,226,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4582ba",endColorstr="#38b4e2",GradientType=1);
  }
}
</style>