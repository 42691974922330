<template>
  <div class="about">
    <div class="pdfView" v-if="pdfUrl">
      <pdf :src="pdfUrl" >
      </pdf>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from '@/components/NavBar.vue'
import Footer from '@/components/Footer.vue'
import pdf from 'vue-pdf'

export default {
  name: 'FileViewer',
  components: {
    NavBar,
    Footer,
    pdf
  },
  data(){
    return {
      chapter:[],
      pdfUrl:"",
      page: 1,
      numPages: 0,
      pdfdata: undefined,
      errors: [],
      scale: 'page-width'
    }
  },
  computed: {
    formattedZoom () {
        return Number.parseInt(this.scale * 100);
    },
    titulo(){
      return this.$store.state.activeTitle;
    }
  },
  beforeMount() {
    if(this.$store.state.sections.length > 0){
      this.getContenido();
    } else {
      this.$store.dispatch("getSections")
      .then(()=>{
        this.getContenido();
      })
    }
    
  },
  mounted(){
    console.log("video",this.chapter,this.$route.params)
    this.title = this.$route.params.id ? this.$route.params.id : "";
    
  },
  metaInfo(){
    return { 
     title: this.$route.params.seccion + " | " + this.titulo
    }
  },
  methods:{
    login(){
      //TODO log user
    },
    getContenido(){
      //Check if active section and active title are set
      if(!this.$store.activeChapter){
        this.$store.dispatch("setChapterBySlug",this.$route.params.id)
        .then(()=>{
          this.chapter = this.$store.state.activeChapter;
          console.log(this.chapter);
          this.showPDF(this.chapter);
        })
      }
    },
    showPDF(c){
      
      this.$store.dispatch("getFile",c._id)
      .then((url)=>{
        //Open PDF
        this.pdfUrl = url;
      })
      .catch((err)=>{
        console.log(err);
      })
    },
  }
}
</script>


<style lang="scss">

.about{
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
}

.pdfView{
  margin: 0 auto;
  width: 100vw;
  height: 100vh;;
  object-fit: fill;
}

iframe{
  width: 100vw;
  height: 100vh;
  object-fit: fill;
}

</style>