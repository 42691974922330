<template>
  <v-navigation-drawer permanent expand-on-hover>
    <v-divider></v-divider>

    <v-list dense>
      <v-list-item link @click="openPortadas">
        <v-list-item-icon>
          <v-icon>mdi-star</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Portadas</v-list-item-title>
      </v-list-item>
      <v-list-item link @click="openRevistas">
        <v-list-item-icon>
          <v-icon>mdi-book-open-page-variant</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Revistas</v-list-item-title>
      </v-list-item>
      <v-list-item link @click="openBoletas">
        <v-list-item-icon>
          <v-icon>mdi-book-settings-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Boletas</v-list-item-title>
      </v-list-item>
      <v-list-item link @click="openCapsulas">
        <v-list-item-icon>
          <v-icon>mdi-atom-variant</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Cápsulas</v-list-item-title>
      </v-list-item>
      <v-list-item link @click="openDialogos">
        <v-list-item-icon>
          <v-icon>mdi-account-switch-outline</v-icon> 
        </v-list-item-icon>
        <v-list-item-title>Diálogos</v-list-item-title>
      </v-list-item>
      <v-list-item link @click="openComunicados">
        <v-list-item-icon>
          <v-icon>mdi-account-tie-voice</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Comunicados</v-list-item-title>
      </v-list-item>
      <v-list-item link @click="openConecta">
        <v-list-item-icon>
          <v-icon>mdi-account-switch-outline</v-icon> 
        </v-list-item-icon>
        <v-list-item-title>Conecta</v-list-item-title>
      </v-list-item>
      <v-list-item link @click="openTLT">
        <v-list-item-icon>
          <v-icon>mdi-account-switch-outline</v-icon> 
        </v-list-item-icon>
        <v-list-item-title>Tu Labor Transforma</v-list-item-title>
      </v-list-item>
      <v-list-item link @click="openCamp">
        <v-list-item-icon>
          <v-icon>mdi-folder</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Campañas</v-list-item-title>
      </v-list-item>
      <v-list-item link @click="openSection('ext1')">
        <v-list-item-icon>
          <v-icon>mdi-folder</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Extra 1</v-list-item-title>
      </v-list-item>
      <v-list-item link @click="openSection('ext2')">
        <v-list-item-icon>
          <v-icon>mdi-folder</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Extra 2</v-list-item-title>
      </v-list-item>
      <v-list-item link @click="openSection('ext3')">
        <v-list-item-icon>
          <v-icon>mdi-folder</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Extra 3</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "NavBar",
  props: {
    msg: String,
  },
  methods:{
    openPortadas(){
      this.$router.push("/admin/portadas")
    },
    openRevistas(){
      this.$router.push("/admin/revistas")
    },
    openBoletas(){
      this.$router.push("/admin/boletas")
    },
    openCapsulas(){
      this.$router.push("/admin/capsulas")
    },
    openDialogos(){
      this.$router.push("/admin/dialogos")
    },
    openConecta(){
      this.$router.push("/admin/conecta")
    },
    openComunicados(){
      this.$router.push("/admin/comunicados")
    },
    openCamp(){
      this.$router.push("/admin/camp")
    },
    openTLT(){
      this.$router.push("/admin/tulabor")
    },
    openSection(s){
      this.$router.push({path:"/admin/extra/"+s})
    }
    
  }
};
</script>

<style lang="scss">
.v-navigation-drawer{
  position: relative;
  top:0;
  left:0;
  height: 100%;
  min-height:100vh;
  margin: 0;
}
</style>
