<template>
  <v-app>
    <v-main class="pa-1">
        <AdminNavBar />
        <div class="container">
            <h1>Contenido Sección {{titulo}}</h1>
            <div class="portada">
              <div class="col">Cambiar Portada (fondo)</div>
              <div class="col" v-if="contenido.fondo">
                <img :src="contenido.fondo" class="pthumb" alt="" srcset="">
              </div>
              <div class="col" v-else>
                <v-file-input
                  chips
                  accept="image/png, image/jpeg"
                  prepend-icon="mdi-camera"
                  label="Subir Fondo"
                  v-model="fondo"
                ></v-file-input>
              </div>
              <div class="col" v-if="contenido.fondo">
                <v-btn color="red" small dark @click="contenido.fondo=null">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </div>
              <div class="col" v-else>
                <v-btn color="red" small dark @click="guardarFondo">
                  Guardar
                </v-btn>
              </div>
            </div>
            <v-data-table
                :headers="headers"
                :items="contenido.capitulos"
                sort-by="name"
                class="elevation-1"
            >
            <template v-slot:top>
            <v-toolbar flat>
              <v-dialog v-model="dialog" max-width="700px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="green"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Agregar
                  </v-btn>
                </template>
               
                <v-card v-if="editedItem" py-5>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-text-field
                            label="Titulo"
                            placeholder="Titulo"
                            solo
                            v-model="editedItem.titulo"
                          ></v-text-field>
                      </v-row>
                      <v-row>
                        <v-col v-if="editedItem.portada">
                          <img :src="editedItem.portada" alt="" class="pthumb"><br>
                          <v-btn color="red" small dark @click="editedItem.portada=null">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </v-col>
                        <v-col v-else>
                          <v-file-input
                            chips
                            accept="image/png, image/jpeg"
                            prepend-icon="mdi-camera"
                            label="portada capitulo"
                            v-model="editedItem.pfile"
                          ></v-file-input>
                        </v-col>
                        <v-col v-if="editedItem.video">
                          <video :src="editedItem.video" class="pthumb" controls></video><br>
                          <v-btn color="red" small dark @click="editedItem.video=null">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </v-col>
                        <v-col v-else>
                          <v-file-input
                            chips
                            accept="video/mp4"
                            prepend-icon="mdi-video"
                            label="video capitulo"
                            v-model="editedItem.vfile"
                          ></v-file-input>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red" dark @click="close">
                      Cancelar
                    </v-btn>
                    <v-btn color="green" dark @click="guardarCapitulo">
                      Guardar
                    </v-btn>
                  </v-card-actions>
                  <v-alert dense type="error" v-if="errorMsg">
                    {{errorMsg}}
                  </v-alert>
                  <div class="text-cente my-5" v-if="showLoader" >
                      <v-progress-circular
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                  </div>
                  <v-alert
                    icon="mdi-bullseye"
                    prominent
                    text
                    type="info"
                    v-if="uploading"
                  >
                    Subiendo : {{progreso.toFixed(2)}} %
                  </v-alert>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5"
                    >¿Estás seguro que deseas eliminar?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete"
                      >Cancelar</v-btn
                    >
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                  <v-alert dense type="error" v-if="deleteMsg">
                    {{deleteMsg}}
                  </v-alert>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.portada="{ item }">
            <img :src="item.portada" alt="" srcset="" width="100" />
          </template>
          <template v-slot:item.video="{ item }">
            <video :src="item.video" alt="" srcset="" class="vidthumb" width="100" />
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary" @click="initialize">
              Reset
            </v-btn>
          </template>
            </v-data-table>
        </div>
    </v-main>
  </v-app>
</template>
<script>
import AdminNavBar from "@/components/AdminNavBar.vue";
export default {
  components: {
    AdminNavBar,
  },
    data(){
        return{
          titulo:"Tu Labor Transforma",
          dialog: false,
          dialogDelete: false,
          showLoader :false,
          uploading: false,
          errorMsg:"",
          deleteMsg: "",
          progreso:0,
          fondo:"",
          portada:"",
          video:"",
          pdf:"",
          fondo:"",
          capitulos:[],
          headers: [
            {
              text: "Portada",
              align: "start",
              sortable: false,
              value: "portada",
            },
            { text: "Video", value: "video" },
            { text: "Acciones", value: "actions", sortable: false },
          ],
          editedIndex:-1,
          editedItem:{fecha:"",portada:"",titulo:"",video:"",pdf:"",pfile:null,vfile:null},
          defaultItem:{fecha:"",portada:"",titulo:"",video:"",pdf:"",pfile:null,vfile:null}
        }
    },
    computed:{
      formTitle() {
        return this.editedIndex === -1 ? "Agregar "+this.titulo : "Editar "+this.titulo;
      },
      contenido(){
        return this.$store.getters.getTLT;
      } 
    },
    watch: {
      dialog(val) {
        val || this.close();
      },
      dialogDelete(val) {
        val || this.closeDelete();
      },
    },
    beforeMount(){
      this.$store.dispatch("getTLT")
      .then(()=>{
        console.log(this.contenido);
      })
    },
    mounted(){
    
    },
    methods: {
      initialize(){
        this.$store.dispatch("getTLT");
      },
      editItem(item) {
        this.editedIndex = this.contenido.capitulos.indexOf(item);
        this.editedItem = Object.assign({}, item);
        console.log(this.editedIndex)
        this.dialog = true;
      },
      deleteItem(item) {
        this.editedIndex = this.contenido.capitulos.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.dialogDelete = true;
      },
      deleteItemConfirm() {
        this.contenido.capitulos.splice(this.editedIndex, 1);
        //TODO save changes to DB
        var self = this;
        var db = firebase.firestore();
        {          
          db.collection("Contenido").doc("TLT").set({
            fondo: this.contenido.fondo,
            capitulos:this.contenido.capitulos
          })
          .then(d=>{
            self.$store.dispatch("getTLT");
            self.closeDelete();
          })
          .catch((err)=>{
            console.log(err);
            self.deleteMsg = "Ocurrión un error al intentar borrar el documento";
          })
        }

      },
      close() {
        this.dialog = false;
        this.showLoader = false;
        this.uploading = false;
        this.portada="";
        this.pdf="";
        this.video="";
        this.errorMsg = "";
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1;
        });
      },
      closeDelete() {
        this.dialogDelete = false;
        this.deleteMsg = "";
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1;
        });
      },
      guardarCapitulo(){
        this.errorMsg = "";
        this.clearTempData();
        if(!(this.editedItem.video || this.editedItem.vfile)){
          this.errorMsg= "Debes subir un video en mp4";
        } else if(!(this.editedItem.portada || this.editedItem.pfile)){
          this.errorMsg= "Debes subir la portada";
        } else if(!this.editedItem.titulo){
          this.errorMsg= "Debes agregar un título";
        }else {
          this.uploading = true;
          this.showLoader = true;
          this.saveCapitulo(); 
        }

      },
      saveCapitulo(){
        this.editedItem.portada ? this.portada = this.editedItem.portada : this.portada = "";
        this.editedItem.video ? this.video = this.editedItem.video : this.video = "";
        this.editedItem.pdf ? this.pdf = this.editedItem.pdf : this.pdf = "";
        if(this.editedItem.titulo && this.editedItem.video){
          this.saveToDB();
        } else {
          if(!this.editedItem.portada && this.editedItem.pfile && this.editedItem.portada){
            this.saveFile(this.editedItem.pfile)
            .then((downloadURL)=>{
              this.portada = downloadURL;
              this.editedItem.portada = downloadURL;
              this.video ? this.saveToDB() : this.saveCapitulo();
            })
            .catch(()=>{
              this.errorMsg = "Ocurrió un error al subir la portada, intenta de nuevo";
              this.showLoader = false;
            })
          } else if(!this.editedItem.video && this.editedItem.vfile){
            this.saveFile(this.editedItem.vfile)
            .then((downloadURL)=>{
              this.video = downloadURL;
              this.editedItem.video = downloadURL;
              this.portada ? this.saveToDB() : this.saveCapitulo();
            })
            .catch(()=>{
              this.errorMsg = "Ocurrió un error al subir el video, intenta de nuevo";
              this.showLoader = false;
            })
          }
        }
      },
      guardarFondo(){
        this.saveFile(this.fondo)
        .then((downloadURL)=>{
          this.contenido.fondo = downloadURL;
          this.saveDBFondo();
        })
        .catch(err=>{
          console.error(err);
        })
      },
      saveFile(file) {
        return new Promise((resolve, reject)=>{
          var self = this;
          var uploadFile = file;
          self.progreso = 0;
          var storageRef = firebase.storage().ref();
          var uploadTask2 = storageRef.child("tlt/" + uploadFile.name).put(uploadFile);
          uploadTask2.on(
            "state_changed",
            function(snapshot) {
              self.progreso =(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              //console.log("Subido " + self.progreso + "%");
            },
            (error) => {
              console.error(error);
              reject(error);
            },
            () => {
              uploadTask2.snapshot.ref.getDownloadURL().then((downloadURL) => {
                resolve(downloadURL);
                //console.log("Archivo en", downloadURL);
              });
            }
          );
        })
      },
      saveDBFondo(){
        var db = firebase.firestore();
                  
        db.collection("Contenido").doc("TLT").set({
          fondo: this.contenido.fondo,
          capitulos:this.contenido.capitulos ? this.contenido.capitulos : []
        })
        .then(d=>{
          this.$store.dispatch("getTLT");
        })
        .catch((err)=>{
          console.log(err);
        })
      },
      saveToDB(){
        console.log("guardando info",this.editedItem,this.editedIndex)
        if(this.editedIndex>=0){
          this.contenido.capitulos[this.editedIndex]={ 
            fecha:Date.now(), 
            titulo:this.editedItem.titulo ? this.editedItem.titulo : this.boletines.capitulos[this.editedIndex].titulo,
            portada: this.editedItem.portada ? this.editedItem.portada : this.contenido.capitulos[this.editedIndex].portada,
            video: this.editedItem.video ? this.editedItem.video : this.contenido.capitulos[this.editedIndex].video,
            pdf: this.editedItem.pdf ? this.editedItem.pdf : this.contenido.capitulos[this.editedIndex].pdf
          }
        } else {
          this.contenido.capitulos ? '' : this.contenido.capitulos = [];
          this.contenido.capitulos.push( {
            fecha:Date.now(),
            titulo:this.editedItem.titulo,
            portada:this.editedItem.portada,
            video: this.editedItem.video,
            pdf: this.editedItem.pdf,
          })
        }
        console.log(this.contenido.capitulos);
        var db = firebase.firestore();
                  
        db.collection("Contenido").doc("TLT").set({
          fondo: this.contenido.fondo ? this.contenido.fondo : "",
          capitulos:this.contenido.capitulos
        })
        .then(d=>{
          this.$store.dispatch("getTLT");
        })
        .catch((err)=>{
          console.log(err);
        })
        
        this.showLoader = false;
        this.clearTempData();
        this.close();
      },
      clearTempData(){
        this.portada = "";
        this.video = "";
        this.pdf = "";
        this.fondo = "";
        this.errorMsg = "";
        this.uploading =false;
        this.deleteMsg = "",
        this.showLoader = false;
      }
    }
    
}
</script>
<style lang="scss" scoped>

.v-main__wrap {
  display: flex;
}
.portada{
  padding: 15px;
  display: flex;
  flex-direction: row;
  justify-content:space-evenly;
  background-color: #fff;
  margin:15px auto;
  width: 100%;
  color:#000;
}

.pthumb {
  margin: 5px auto;
  padding:0;
  width:120px;
}

</style>