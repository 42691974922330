<template>
  <div id="seccion">
    <div class="play">
      
    </div>
    <div class="strip">
      <div class="thumb" v-for="(s,index) in contenido" :key="index" @click="verContenido(s)">
        <img :src="urlFor(s.mainImage,300)" alt="">
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from '@/components/NavBar.vue'
import Footer from '@/components/Footer.vue'
import imageUrlBuilder from '@sanity/image-url'

const builder = imageUrlBuilder({
  projectId: 'j5fl1tzn',
  dataset: 'production',
})

export default {
  name: 'Seccion',
  components: {
    NavBar,
    Footer
  },
  data(){
    return{
      contenido: [],
      seccion:{}
    }
  },
  mounted(){
    
    console.log("seccion",this.$route.params.id,this.$store.state.activeSection)
    if(this.$store.state.sections.length > 0){
      this.getContenido();
    } else {
      this.$store.dispatch("getSections")
      .then(()=>{
        this.getContenido();
      })

    }
    
  },
  metaInfo(){
    return { 
     title: this.$route.params.id,
    }
  },
  methods:{
    getContenido(){
      //TODO check ID and get content
      if(!this.$store.state.activeSection){
        this.$store.dispatch("setSectionByID",this.$route.params.id)
        .then(()=>{
          console.log("seccion",this.$route.params.id,this.$store.state.activeSection)
          this.$store.dispatch("getActiveSectionContent").then(()=>{
            this.sections = this.$store.state.sections;
            this.seccion = this.$store.state.activeSection;
            this.contenido = this.$store.state.activeSectionContent;
            this.setCover();
          })
        })
      } else{
        this.$store.dispatch("getActiveSectionContent").then(()=>{
            this.sections = this.$store.state.sections;
            this.seccion = this.$store.state.activeSection;
            this.contenido = this.$store.state.activeSectionContent;
            this.setCover();
          })
      }
      
    },
    setCover(){
      this.coverImg = this.urlFor(this.seccion.fondo,1920);
      console.log(this.coverImg)
      document.getElementById("seccion").style.backgroundImage = "linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.7)), url('"+this.coverImg+"')";
    },
    urlFor(source,w=150) {
      //console.log(source.portada.asset._ref)
      if(source == undefined) return "https://placehold.co/1920";
      else{
        return builder.image(source.asset._ref).width(w).url()
      }
      
    },
    verContenido(chapter){
      //Guardamos Capítulo Seleccionado 
      console.log("chapter",chapter);
      console.log("seccion",this.seccion)
      this.$store.dispatch("setTitle",chapter.title);
      this.$store.dispatch("setActiveChapter",chapter)
      .then(()=>{
        if(chapter.video){
          this.$router.push("/video/"+this.seccion.slug.current+"/"+chapter.slug.current)
        } else if( chapter.file){
          this.$router.push("/revista/"+this.seccion.slug.current+"/"+chapter.slug.current)
        } else{
          alert("No hay contenido disponible");
        }
      })
      .catch((err)=>{
        console.log(err)
      })
      
      //Revisamos si es video o revista
      
    }
  }
}
</script>


<style lang="scss">

#seccion{
  margin:0;
  padding: 0;
  width: 100%;
  min-height: 100vh;
  background-position: top center;
	background-repeat: no-repeat;
  background-size: 250%;
	position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content:flex-start;
  align-items:flex-end;
  align-content: flex-end;
  overflow: hidden;

  @media screen and (min-width:760px) {
    background-size: cover;
    background-position: center center;
  }

  .play{
    margin: 0 0 20px 20px;
  }

}

.strip{
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap:wrap;
  justify-content:flex-start;
  align-items: stretch;
  align-content: center;
  width:100%;
  
  overflow-x:auto;

  .thumb{
    position: relative;
    margin: 0;
    padding:0;
    height: fit-content;
    width: 50vw;
    cursor: pointer;
  }

  .title{
    position: absolute;
    bottom: 10px;
    left: 10px;
    font-size:0.8rem;
  }

  img{
    margin: 0;
    padding: 0;
    width: 100%;
  }

  @media screen and (min-width:760px) {

      flex-wrap: nowrap;
      height: fit-content;

      .thumb{
        height: fit-content;
        width: 50vw;
      }

      img{
        width: 100%;
      }
  }

  @media screen and (min-width:1020px) {
    .thumb{
        height: fit-content;
        width: 30vw;
      }
  }

  @media screen and (min-width:1280px) {
    .thumb{
        height: fit-content;
        width: 20vw;
      }
  }

}

</style>