<template>
  <div id="seccion">
    <div class="play">
      
    </div>
    <div class="strip">
      <div class="thumb" v-for="(s,index) in sections.capitulos" :key="index" @click="verContenido(index)">
        <img :src="s.portada" alt="">
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from '@/components/NavBar.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Seccion',
  components: {
    NavBar,
    Footer
  },
  data(){
    return{
      sections:[],
      seccion: "",
    }
  },
  mounted(){
    console.log("seccion",this.$store.state.activeSection)
    this.getContenido();
  },
  computed: {

    boletas(){
      return this.$store.getters.getBoletas;
    },
    camp(){
      return this.$store.getters.getCamps;
    },
    comunicados(){
      return this.$store.getters.getComunicados;
    },
    capsulas(){
      return this.$store.getters.getCapsulas;
    },
    dialogos(){
      return this.$store.getters.getDialogos;
    },
    conecta(){
      return this.$store.getters.getConecta;
    },
    revistas(){
      return this.$store.getters.getRevistas;
    }
  },
  watch:{
    sections: function(val){
      this.coverImg = val.fondo;
      document.getElementById("seccion").style.backgroundImage = "url('"+this.coverImg+"')";
    }
  },
  metaInfo(){
    return { 
     title: "Capitulos" + this.$store.state.activeSection,
    }
  },
  methods:{
    getContenido(){
      //TODO check ID and get content
      switch (this.$store.state.activeSection){
        case "boletas":
          //set boletas as contenido
          this.$store.dispatch("getBoletas").then(()=>{
            this.sections = this.boletas;
          })
          break;
        case "campanas":
          //set boletas as contenido
          this.$store.dispatch("getCamps").then(()=>{
            this.sections = this.camp
          })
          break;
        case "comunicados":
          //set boletas as contenido
          this.$store.dispatch("getComunicados").then(()=>{
            this.sections = this.comunicados;
          })
          break;
        case "capsulas":
          //set boletas as contenido
          this.$store.dispatch("getCapsulas").then(()=>{
            this.sections = this.capsulas;
          })
          break;
        case "dialogos":
          //set boletas as contenido
          this.$store.dispatch("getDialogos").then(()=>{
            this.sections = this.dialogos;
          })
          break;
        case "revistas":
          //set boletas as contenido
          this.$store.dispatch("getRevistas").then(()=>{
            this.sections = this.revistas;
          })
          break;
        case "conecta":
          //set boletas as contenido
          this.$store.dispatch("getConecta").then(()=>{
            this.sections = this.conecta;
          })
          break;
        default:
            break;
      }
    },
    setCover(){
      this.coverImg = this.sections.fondo ? this.sections.fondo : '';
      console.log(this.coverImg);
      document.getElementById("seccion").style.backgroundImage = "linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.7)), url('"+this.coverImg+"')";
    },
    verContenido(n){
      //go to capitulos and send data  
      
      if(this.sections.capitulos[n].video){
        this.$store.dispatch("setChapter",this.sections.capitulos[n])
        .then(()=>{
          this.$router.push("/video/"+this.sections.capitulos[n].fecha)
        })
      } else {
        this.$store.dispatch("setChapter",this.sections.capitulos[n])
        .then(()=>{
          this.$router.push("/revista/"+this.sections.capitulos[n].fecha)
        })
      }
    }
  }
}
</script>


<style lang="scss">

#seccion{
  margin:0;
  padding: 0;
  width: 100%;
  min-height: 100vh;
  background-position: top center;
	background-repeat: no-repeat;
  background-size: 250%;
	position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content:flex-start;
  align-items:flex-end;
  align-content: flex-end;
  overflow: hidden;

  @media screen and (min-width:760px) {
    background-size: cover;
  }

  .play{
    margin: 0 0 20px 20px;
  }

}

.strip{
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap:wrap;
  justify-content:flex-start;
  align-items: stretch;
  align-content: center;
  width:100%;
  overflow-x:auto;

  .thumb{
    position: relative;
    margin: 0;
    padding:0;
    height: fit-content;
    width: 50vw;
    cursor: pointer;
  }

  .title{
    position: absolute;
    bottom: 10px;
    left: 10px;
    font-size:0.8rem;
  }

  img{
    margin: 0;
    padding: 0;
    width: 100%;
  }

  @media screen and (min-width:760px) {

      flex-wrap: nowrap;

      .thumb{
        width: 200px;
      }

      img{
        width: 200px;
      }
  }

}

</style>